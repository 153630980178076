import React, { useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { FaInstagram, FaFacebookF, FaLinkedinIn, FaYoutube, FaChevronDown } from "react-icons/fa";

import LanguageContext from '../contexts/language';

import logo from '../assets/images/logo.png';
import logo_btn from '../assets/images/logo_black.png';
import br from '../assets/images/br.png';
import uk from '../assets/images/uk.png';

export default function Header() {

    const { language, setLanguageStore } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');

    function setLanguage(lang) {
        setLanguageStore(lang);
    }

    return (
        <>
        <div className="header-container">
            <div className="container-fluid">
                <div className="header-content">
                    <div className="header-social">
                        <Link to="/" className="menu-logo">
                            <img src={logo} />
                        </Link>
                    </div>
                    <div className="header-container-menu">
                        <div className="menu-language-digital">
                            <a href="https://www.instagram.com/brukfit/" target="_blank" className="menu-icon">
                                <FaInstagram />
                            </a>
                            <a href="https://www.facebook.com/brukfit" target="_blank" className="menu-icon">
                                <FaFacebookF />
                            </a>
                            <a href="https://www.linkedin.com/company/brukfit" target="_blank" className="menu-icon">
                                <FaLinkedinIn />
                            </a>
                            <a href="https://www.youtube.com/@brukfit" target="_blank" className="menu-icon me-4">
                                <FaYoutube />
                            </a>
                            <div className="menu-language">
                                <a className={`menu-language-logo ${lang=='pt' ? 'active' : ''}`} onClick={() => setLanguage('pt')}><img src={br} /></a>
                                <a className={`menu-language-logo ${lang=='en' ? 'active' : ''}`} onClick={() => setLanguage('en')}><img src={uk} /></a>
                            </div>
                            <a href="https://gym.bruk.digital" target="_blank" className="menu-digital">
                                <div className="menu-digital-logo"><img src={logo_btn} /></div>
                                <div className="menu-digital-text">Digital</div>
                            </a>
                        </div>
                        <div className="header-menu">
                            <NavLink to="/quem-somos" className="menu-text" activeClassName="active">
                                <div className="menu-item-text">{language.menu_about}</div>
                            </NavLink>
                            <div className="menu-text" activeClassName="active">
                                <div className="menu-item-text">{language.menu_branch}</div>
                                <div className="menu-item-icon"><FaChevronDown /></div>
                                <div className="menu-ul">
                                    <a href="https://ipanema.bruk.digital/" target="_blank">BRUK Ipanema</a>
                                    <a href="https://konnen.bruk.digital/" target="_blank">BRUK Konnen</a>
                                    <a href="javascript:;" target="_blank">BRUK Konnen VP</a>
                                    <a href="javascript:;" target="_blank">BRUK Barra</a>
                                    <a href="javascript:;" target="_blank">BRUK Petrópolis</a>
                                    <a href="javascript:;" target="_blank">BRUK Valle</a>
                                    <a href="javascript:;" target="_blank">BRUK Itaipava</a>
                                    <a href="javascript:;" target="_blank">BRUK Praia</a>
                                    <a href="javascript:;" target="_blank">BRUK Bauru</a>
                                </div>
                            </div>
                            <NavLink to="/franquia" className="menu-text" activeClassName="active">
                                <div className="menu-item-text">{language.menu_bruk}</div>
                            </NavLink>
                            <NavLink to="/xperience" className="menu-text" activeClassName="active">
                                <div className="menu-item-text">Xperience</div>
                            </NavLink>
                            <a href="https://reserva.ink/bruk.fit" target="_blank" className="menu-text">
                                <div className="menu-item-text">{language.menu_store}</div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}