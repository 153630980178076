import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import { Carousel } from 'react-responsive-carousel';

import gus from '../assets/images/gus.png';

import LanguageContext from '../contexts/language';

export default function SessionSix({ content }) {
    
    const { language } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');

    return (
        <div className="session-six">
            <div className="container-xl">
                <div className="row">
                    <div className="col-md-4 d-flex align-items-center justify-content-center flex-column">
                        <div className="heading-block text-center">
                            <h2 data-aos="fade-up" data-aos-delay="50" dangerouslySetInnerHTML={{__html: lang=='pt' ? content.title : content.title_en }} />
                        </div>
                        <div className="session-form" data-aos="fade-up" data-aos-delay="100">
                            <input className="form-control session-input" placeHolder={language.input_name} type="text" />
                            <input className="form-control session-input" placeHolder={language.input_email} type="email" />
                            <input className="form-control session-input" placeHolder={language.input_phone} type="text" />
                            <div className="session-button">
                                <a href="javascript:;" className="btn btn-primary btn-lg">{language.send}</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 d-flex align-items-center">
                        <div className="heading-block text-center">
                            <h2 data-aos="fade-down" data-aos-delay="100" dangerouslySetInnerHTML={{__html: lang=='pt' ? content.subtitle : content.subtitle_en }} />
                            <div data-aos="fade-down" data-aos-delay="150" dangerouslySetInnerHTML={{__html: lang=='pt' ? content.description : content.description_en }} />
                            <div className="session-button" data-aos-delay="200">
                                <a href="javascript:;" className="btn btn-primary btn-lg">{lang=='pt' ? content.link_text : content.link_text_en}</a>
                            </div>
                            <div className="session-name" data-aos-delay="250">Gus Vaz Tostes</div>
                        </div>
                    </div>
                    <div className="col-md-4 z-index" data-aos="fade-up" data-aos-delay="300">
                        <img src={gus} className="gus" />
                    </div>
                    
                </div>
            </div>
        </div>
    );
}