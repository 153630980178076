import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaInstagram } from "react-icons/fa";

import LanguageContext from '../contexts/language';

export default function SessionTen({ content }) {

    const { language } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');

    return (
        <div className="session-ten">
            <div className="container-xl">
                <div className="row">
                    <div className="col-md-8 m-auto">
                        <div className="heading-block text-center">
                            <h2 data-aos="fade-down" data-aos-delay="100" dangerouslySetInnerHTML={{__html: lang=='pt' ? content.title : content.title_en }} />
                            <h3 data-aos="fade-down" data-aos-delay="150" dangerouslySetInnerHTML={{__html: lang=='pt' ? content.description : content.description_en }} />
                            <div className="session-button" data-aos-delay="200">
                                <Link to="/franquia" className="btn btn-primary btn-lg">{lang=='pt' ? content.link_text : content.link_text_en}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}