import React, { useContext } from 'react';
import ReactPlayer from 'react-player';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import { Carousel } from 'react-responsive-carousel';

import Base from '../assets/images/blank-video.png';

import image_2 from '../assets/images/test/image_2.jpg';
import image_5 from '../assets/images/test/image_5.jpg';

import LanguageContext from '../contexts/language';

export default function SessionThree({ content, items }) {

    const { language } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');

    return (
        <div className="session-three session-bg">
            <div className="container-xl">
                <div className="row">
                    <div className="col-md-7" data-aos="fade-up" data-aos-delay="100">
                        <Carousel
                            className="carousel-left"
                            transitionTime={300}
                            infiniteLoop={true}
                            autoPlay={false}
                            showArrows={true}
                            showIndicators={false}
                            showStatus={false}
                            showThumbs={false}
                            stopOnHover={false}
                            renderArrowPrev={(onClickHandler, hasPrev, label) =>
                                hasPrev && (
                                    <a onClick={onClickHandler} title={label} className="btn-prev">
                                        <FiChevronLeft />
                                    </a>
                                )
                            }
                            renderArrowNext={(onClickHandler, hasNext, label) =>
                                hasNext && (
                                    <a onClick={onClickHandler} title={label} className="btn-next">
                                        <FiChevronRight />
                                    </a>
                                )
                            }
                        >   
                            {items.map(item => (<>
                                {item.type==="1" ?
                                <div className="session-item">
                                    <img src={Base} className="image-rounded image-cover" style={{backgroundImage: `url(${item.image})`}} />
                                </div>:
                                <div className="session-item">
                                    <div className="embed-container">
                                        <ReactPlayer
                                            url={item.video}
                                            controls={true}
                                            playsinline={true}
                                            playing={false}
                                            frameBorder="0"
                                            allowFullScreen
                                        />
                                    </div>
                            </div>}
                            </>))}
                        </Carousel>
                    </div>
                    <div className="col-md-5 d-flex align-items-center" data-aos="fade-up" data-aos-delay="200">
                        <div className="heading-block text-center">
                            <h2 data-aos="fade-down" data-aos-delay="100" dangerouslySetInnerHTML={{__html: lang=='pt' ? content.title : content.title_en }} />
                            <div data-aos="fade-down" data-aos-delay="200" dangerouslySetInnerHTML={{__html: lang=='pt' ? content.description : content.description_en }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}