import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import { Carousel } from 'react-responsive-carousel';
import { BsApple, BsAndroid2 } from "react-icons/bs";

import Base from '../assets/images/blank-post-vertical.png';

import app_1 from '../assets/images/app_1.png';
import app_2 from '../assets/images/app_2.png';
import app_3 from '../assets/images/app_3.png';
import app_4 from '../assets/images/app_4.png';

import LanguageContext from '../contexts/language';

export default function SessionSeven({ content }) {
    
    const { language } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');

    return (
        <div className="session-seven session-bg">
            <div className="container-xl">
                <div className="row">
                    <div className="col-md-8" data-aos="fade-up" data-aos-delay="100">
                        <div className="row ms-2 me-2">
                            <Carousel
                                animationHandler="fade"
                                className="carousel-left z-index"
                                swipeable={false}
                                transitionTime={300}
                                infiniteLoop={true}
                                autoPlay={true}
                                interval={5000}
                                showArrows={true}
                                showIndicators={false}
                                showStatus={false}
                                showThumbs={false}
                                stopOnHover={false}
                                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                                    hasPrev && (
                                        <a onClick={onClickHandler} title={label} className="btn-prev">
                                            <FiChevronLeft />
                                        </a>
                                    )
                                }
                                renderArrowNext={(onClickHandler, hasNext, label) =>
                                    hasNext && (
                                        <a onClick={onClickHandler} title={label} className="btn-next">
                                            <FiChevronRight />
                                        </a>
                                    )
                                }
                            >   
                                <img src={app_1} className="img-fluid" />
                                <img src={app_2} className="img-fluid" />
                                <img src={app_3} className="img-fluid" />
                                <img src={app_4} className="img-fluid" />
                            </Carousel>
                        </div>
                    </div>
                    <div className="col-md-4 d-flex align-items-center" data-aos="fade-up" data-aos-delay="100">
                        <div className="heading-block text-center">
                            <h2 data-aos="fade-down" data-aos-delay="100" dangerouslySetInnerHTML={{__html: lang=='pt' ? content.title : content.title_en }} />
                            <div data-aos="fade-down" data-aos-delay="150" dangerouslySetInnerHTML={{__html: lang=='pt' ? content.description : content.description_en }} />
                            <div className="session-downloads">
                                <a href="https://apps.apple.com/br/app/bruk/id6478118912" target="_blank"><BsApple /></a>
                                <a href="https://play.google.com/store/apps/details?id=com.etc.bruk&hl=pt" target="_blank" className="text-green"><BsAndroid2 /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}