import React, { createContext, useState, useEffect } from 'react';

const LanguageContext = createContext();

const languages = {
    pt: {
        language: "pt",
        language_pt: "Português",
        language_en: "Inglês",
        language_sigla: "PT",
        language_pt_inverse: "Português",
        language_en_inverse: "English",
        menu_home: "Home",
        menu_about: "Quem Somos",
        menu_contact: "Contato",
        menu_logout: "Sair",
        menu_store: "Loja",
        menu_branch: "Unidades",
        menu_bruk: "Tenha uma BRUK",
        schedule: "AGENDE SUA AULA EXPERIMENTAL",
        send: "ENVIAR",
        partners: "Parceiros oficiais",
        newsletter: "Seja o primeiro a saber as novidades BRUK",
        register: "CADASTRAR",
        copyright: "Todos os direitos reservados.",
        developed: "Desenvolvido por",
        input_name: "nome",
        input_email: "e-mail",
        input_phone: "whatsapp",
        input_subject: "assunto",
        input_message: "mensagem",
        franchisee: "Torne-se um Franqueado",
        form_name: "Nome",
        form_email: "E-mail",
        form_phone: "Telefone",
        form_city: "Em qual cidade gostaria de abrir sua BRUK?",
        form_message: "Nos conte um pouco sobre você",
        send_success: "Informações enviadas com sucesso!",
    },
    en: {
        language: "en",
        language_pt: "Portuguese",
        language_en: "English",
        language_sigla: "EN",
        language_pt_inverse: "Português",
        language_en_inverse: "English",
        menu_home: "Home",
        menu_about: "About Us",
        menu_contact: "Contact",
        menu_logout: "Logout",
        menu_store: "Store",
        menu_branch: "Branches",
        menu_bruk: "Get a BRUK",
        schedule: "SCHEDULE YOUR EXPERIMENTAL CLASS",
        send: "SEND",
        partners: "Official partners",
        newsletter: "Be the first to know BRUK news",
        register: "REGISTER",
        copyright: "All rights reserved.",
        developed: "Developed by",
        input_name: "name",
        input_email: "email",
        input_phone: "whatsapp",
        input_subject: "subject",
        input_message: "message",
        franchisee: "Become a Franchisee",
        form_name: "Name",
        form_email: "Email",
        form_phone: "Phone",
        form_city: "In which city would you like to open your BRUK?",
        form_message: "Tell us a little about you",
        send_success: "Information sent successfully!",
    }
}

export const LanguageProvider = ({ children }) => {
    
    const [language, setLanguage] = useState({});

    async function loadStorage() {
        const storageLanguage = localStorage.getItem('language');
        if (!storageLanguage) {
            const userLang = navigator.language || navigator.userLanguage; 
            if(userLang=="pt-BR"){
                setLanguageStore('pt');
            }else{
                setLanguageStore('en');
            }
        }else{
            setLanguageStore(storageLanguage);
        }
    }

    async function setStorage(lang) {
        localStorage.setItem('language', lang);
    }

    async function setLanguageStore(lang){
        if(lang==="pt" || lang==="PT"){
            setLanguage(languages.pt);
            setStorage("pt");
        }else{
            setLanguage(languages.en);
            setStorage("en");
        }
    }

    useEffect(() => {
        loadStorage();
    }, [language]);

    return (
        <LanguageContext.Provider value={{ language, setLanguage, setLanguageStore }} >
            {children}
        </LanguageContext.Provider>
    )

}

export default LanguageContext;