import React, { useContext } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import { Carousel } from 'react-responsive-carousel';

import Base from '../assets/images/blank-banner.png';

import LanguageContext from '../contexts/language';

export default function Banner({ items }) {

    const { language } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');

    return (
        <div className="session-banner">
            <div className="container-fuid">
                {items &&
                <div className="row">
                    <div className="col-md-12">
                        <Carousel
                            className="carousel-left"
                            transitionTime={300}
                            infiniteLoop={true}
                            autoPlay={false}
                            showArrows={true}
                            showIndicators={false}
                            showStatus={false}
                            showThumbs={false}
                            stopOnHover={false}
                            renderArrowPrev={(onClickHandler, hasPrev, label) =>
                                hasPrev && (
                                    <a onClick={onClickHandler} title={label} className="btn-prev">
                                        <FiChevronLeft />
                                    </a>
                                )
                            }
                            renderArrowNext={(onClickHandler, hasNext, label) =>
                                hasNext && (
                                    <a onClick={onClickHandler} title={label} className="btn-next">
                                        <FiChevronRight />
                                    </a>
                                )
                            }
                        >   
                            {items.map(item => (
                                <>
                                {item.link ?
                                <a href={item.link} target="_blank" className="session-item">
                                    <img src={Base} className="image-cover" style={{backgroundImage: `url(${item.image})`}} />
                                    <div className="session-items">
                                        {item.text &&
                                        <div className="session-text">
                                            <p>{lang=='pt' ? item.text : item.text_en }</p>
                                        </div>}
                                        {item.link_text &&
                                        <div className="session-button">
                                            <div className="btn btn-primary btn-lg">{lang=='pt' ? item.link_text : item.link_text_en }</div>
                                        </div>}
                                    </div>
                                </a>:
                                <div className="session-item">
                                    <img src={Base} className="image-cover" style={{backgroundImage: `url(${item.image})`}} />
                                    <div className="session-items">
                                        {item.text &&
                                        <div className="session-text">
                                            <p>{lang=='pt' ? item.text : item.text_en }</p>
                                        </div>}
                                    </div>
                                </div>}
                            </>))}
                        </Carousel>
                    </div>
                </div>}
            </div>
        </div>
    );
}