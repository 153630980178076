import React, { useState, useEffect, useContext } from 'react';
import { RiEyeFill, RiHeartsFill, RiAwardFill } from "react-icons/ri";

import api from '../../services/api';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Shimmer from '../../components/Shimmer';

import banner from '../../assets/images/banner.jpg';
import bruk_about from '../../assets/images/bruk_about.png';
import bruk_equipe from '../../assets/images/bruk_equipe.jpg';

import LanguageContext from '../../contexts/language';

export default function QuemSomos() {
    
    const { language } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');

    const [loading, setLoading] = useState(true);
    const [content, setContent] = useState([]);

    async function loadPage(){
        try{
            const response = await api.post('/site-page/quem-somos');
            setContent(response.data.data.content);
            setLoading(false);
        }catch(err){
            setLoading(false);
        }
    }

    useEffect(() => {
        loadPage();
    }, []);

    return (
        <>
        <div className="header-wrapper">
            <Header />
        </div>
        <div className="page-wrapper">
            <div className="header-space"></div>
            <div className="content-wrapper page-content">
                <div className="row">
                    <div className="content-section section-7" data-aos="fade-up">
                        <div className="row">
                            <div className="col-md-6 m-auto pb-4" data-aos="fade-up" data-aos-delay="50">
                                <h2 dangerouslySetInnerHTML={{__html: lang=='pt' ? content.text_1 : content.text_en_1 }} />
                                <div dangerouslySetInnerHTML={{__html: lang=='pt' ? content.text_2 : content.text_en_2 }} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 m-auto" data-aos="fade-up" data-aos-delay="100">
                            <img className="image-rounded img-fluid" src={bruk_about} />
                        </div>
                    </div>
                    <div className="content-section section-8">
                        <div className="row">
                            <div className="col-md-6 m-auto pb-4" data-aos="fade-up" data-aos-delay="150">
                                <h2 dangerouslySetInnerHTML={{__html: lang=='pt' ? content.text_3 : content.text_en_3 }} />
                                <div dangerouslySetInnerHTML={{__html: lang=='pt' ? content.text_4 : content.text_en_4 }} />
                            </div>
                        </div>
                    </div>

                    <div className="content-section section-9">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-5 m-auto" data-aos="fade-up" data-aos-delay="200">
                                    <h2>
                                        <div className="about-icons"><RiHeartsFill /></div>
                                        <div dangerouslySetInnerHTML={{__html: lang=='pt' ? content.text_5 : content.text_en_5 }} />
                                    </h2>
                                    <div dangerouslySetInnerHTML={{__html: lang=='pt' ? content.text_6 : content.text_en_6 }} />
                                </div>
                                <div className="col-md-5 m-auto" data-aos="fade-up" data-aos-delay="250">
                                    <h2>
                                        <div className="about-icons"><RiEyeFill /></div>
                                        <div dangerouslySetInnerHTML={{__html: lang=='pt' ? content.text_7 : content.text_en_7 }} />
                                    </h2>
                                    <div dangerouslySetInnerHTML={{__html: lang=='pt' ? content.text_8 : content.text_en_8 }} />
                                </div>
                                <div className="col-md-5 m-auto" data-aos="fade-up" data-aos-delay="300">
                                    <h2>
                                        <div className="about-icons"><RiAwardFill /></div>
                                        <div dangerouslySetInnerHTML={{__html: lang=='pt' ? content.text_9 : content.text_en_9 }} />
                                    </h2>
                                    <div dangerouslySetInnerHTML={{__html: lang=='pt' ? content.text_10 : content.text_en_10 }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content-section section-10">
                        <div className="col-md-8 m-auto">
                            <h2 data-aos="fade-up" data-aos-delay="350" dangerouslySetInnerHTML={{__html: lang=='pt' ? content.text_11 : content.text_en_11 }} />
                            <div data-aos="fade-up" data-aos-delay="400" dangerouslySetInnerHTML={{__html: lang=='pt' ? content.text_12 : content.text_en_12 }} />
                        </div>
                        <div className="col-md-12 m-auto">
                            <img src={bruk_equipe} className="img-equipe pt-4" data-aos="fade-up" data-aos-delay="450" />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
        </>
    );
}
