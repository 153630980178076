import React, { useState, useEffect, useContext } from 'react';
import { RiEyeFill, RiHeartsFill, RiAwardFill } from "react-icons/ri";

import api from '../../services/api';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Shimmer from '../../components/Shimmer';

import banner from '../../assets/images/banner.jpg';
import bruk_about from '../../assets/images/bruk_about.png';
import bruk_equipe from '../../assets/images/bruk_equipe.jpg';

import LanguageContext from '../../contexts/language';

export default function Xperience() {
    
    const { language } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');

    const [loading, setLoading] = useState(true);
    const [content, setContent] = useState([]);

    async function loadPage(){
       /*  try{
            const response = await api.post('/site-page/quem-somos');
            setContent(response.data.data.content);
            setLoading(false);
        }catch(err){
            setLoading(false);
        } */
    }

    useEffect(() => {
        loadPage();
    }, []);

    return (
        <>
        <div className="header-wrapper">
            <Header />
        </div>
        <div className="page-wrapper">
            <div className="header-space"></div>
            <div className="content-wrapper page-content">
                <div className="row">
                    
                </div>
            </div>
            <Footer />
        </div>
        </>
    );
}
