import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import InputMask from "react-input-mask";
import Header from '../../components/Header';

import Footer from '../../components/Footer';

import api from '../../services/api';

export default function Contact() {
    
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');

    const [loading, setLoading] = useState(false);

    const [error, setError] = useState(false);
    const [error_message, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);

    async function submitContact(e){
        e.preventDefault();
        setLoading(true);
        try{
            if(name==="" || email==="" || phone==="" || message===""){
                setLoading(false);
                setError(true);
                setErrorMessage('Preencha todos os campos!');
                return;
            }

            const response = await api.post('/contact', {
                name,
                email,
                phone,
                message,
            });

            setError(false);
            setLoading(false);
            setSuccess(true);
            setName('');
            setEmail('');
            setPhone('');
            setMessage('');

        }catch(err){
            setLoading(false);
            setError(true);
            setErrorMessage('Erro ao enviar informações');
        }
    }

    return (
        <>
        <div className="header-wrapper">
            <Header />
        </div>
        <div className="page-wrapper">
            <div className="content-wrapper page-content">
                <h4 className="fw-semibold" data-aos="fade-up" data-aos-delay="50">Contato</h4>
                <div className="row pt-4 pb-4">
                    <div className="col-md-6 m-auto" data-aos="fade-up" data-aos-delay="100">
                        <div className="row gy-3">
                            <div className="col-xl-10 m-auto">
                                <label className="form-label">Nome:</label>
                                <input type="text" className="form-control form-control-light" value={name} onChange={e => setName(e.target.value)} />
                            </div>
                            <div className="col-xl-10 m-auto">
                                <label className="form-label mt-4">E-mail:</label>
                                <input type="email" className="form-control form-control-light" value={email} onChange={e => setEmail(e.target.value)} />
                            </div>
                            <div className="col-xl-10 m-auto">
                                <label className="form-label mt-4">Telefone:</label>
                                <InputMask type="tel" className="form-control form-control-light" mask="(99) 99999-9999" value={phone} onChange={e => setPhone(e.target.value)} />
                            </div>
                            <div className="col-xl-10 m-auto">
                                <label className="form-label mt-4">Mensagem:</label>
                                <textarea className="form-control form-control-light" rows="3" value={message} onChange={e => setMessage(e.target.value)}></textarea>
                            </div>
                            <div className="col-xl-10 m-auto mt-4">
                                <div className="text-end">
                                    <button className="btn btn-primary-light btn-wave waves-effect waves-light" onClick={submitContact}>Enviar</button>
                                </div>
                            </div>
                            {error &&
                            <div className="col-xl-10 m-auto mt-4">
                                <div className="alert alert-danger">
                                    {error_message}
                                </div>
                            </div>}
                            {success &&
                            <div className="col-xl-10 m-auto mt-4">
                                <div className="alert alert-success">
                                    Informações enviadas com sucesso!
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
        </>
    );
}
