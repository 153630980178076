import React, { useContext } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import { Carousel } from 'react-responsive-carousel';

import Base from '../assets/images/blank-image.png';

import LanguageContext from '../contexts/language';

export default function SessionOne({ content, items, items_2 }) {

    const { language } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');

    return (
        <div className="session-one session-bg">
            <div className="container-xl">
                <div className="row">
                    <div className="col-md-4" data-aos="fade-up" data-aos-delay="100">
                        {items &&
                        <Carousel
                            className="carousel-left"
                            transitionTime={300}
                            infiniteLoop={true}
                            autoPlay={false}
                            showArrows={true}
                            showIndicators={false}
                            showStatus={false}
                            showThumbs={false}
                            stopOnHover={false}
                            renderArrowPrev={(onClickHandler, hasPrev, label) =>
                                hasPrev && (
                                    <a onClick={onClickHandler} title={label} className="btn-prev">
                                        <FiChevronLeft />
                                    </a>
                                )
                            }
                            renderArrowNext={(onClickHandler, hasNext, label) =>
                                hasNext && (
                                    <a onClick={onClickHandler} title={label} className="btn-next">
                                        <FiChevronRight />
                                    </a>
                                )
                            }
                        >   
                            {items.map(item => (
                            <a className="session-item">
                                <img src={Base} className="image-rounded image-cover" style={{backgroundImage: `url(${item.image})`}} />
                                <div className="session-text">
                                    <p>{lang=='pt' ? item.text : item.text_en }</p>
                                </div>
                            </a>))}
                        </Carousel>}
                    </div>
                        
                    <div className="col-md-4" data-aos="fade-up" data-aos-delay="200">
                        <div className="heading-block text-center">
                            <h2 data-aos="fade-down" data-aos-delay="100" dangerouslySetInnerHTML={{__html: lang=='pt' ? content.title : content.title_en }} />
                            <div data-aos="fade-down" data-aos-delay="200" dangerouslySetInnerHTML={{__html: lang=='pt' ? content.description : content.description_en }} />
                        </div>
                    </div>
                    <div className="col-md-4" data-aos="fade-up" data-aos-delay="300">
                        {items_2 &&
                        <Carousel
                            className="carousel-right"
                            transitionTime={300}
                            infiniteLoop={true}
                            autoPlay={false}
                            showArrows={true}
                            showIndicators={false}
                            showStatus={false}
                            showThumbs={false}
                            stopOnHover={false}
                            renderArrowPrev={(onClickHandler, hasPrev, label) =>
                                hasPrev && (
                                    <a onClick={onClickHandler} title={label} className="btn-prev">
                                        <FiChevronLeft />
                                    </a>
                                )
                            }
                            renderArrowNext={(onClickHandler, hasNext, label) =>
                                hasNext && (
                                    <a onClick={onClickHandler} title={label} className="btn-next">
                                        <FiChevronRight />
                                    </a>
                                )
                            }
                        >   
                            {items_2.map(item => (
                            <a className="session-item">
                                <img src={Base} className="image-rounded image-cover" style={{backgroundImage: `url(${item.image})`}} />
                                <div className="session-text">
                                    <p>{lang=='pt' ? item.text : item.text_en }</p>
                                </div>
                            </a>))}
                        </Carousel>}
                    </div>
                </div>
            </div>
        </div>
    );
}