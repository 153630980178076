import React, { useState, useContext, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";

import Header from '../../components/Header';
import Content from '../../components/Content';
import Footer from '../../components/Footer';
import Shimmer from '../../components/Shimmer';
import Podcast from '../../components/Podcast';
import ImageText from '../../components/ImageText';
import Videos from '../../components/Videos';
import Gallery from '../../components/Gallery';

import api from '../../services/api';

import base from '../../assets/images/blank-square.png';

export default function News() {

    const { url } = useParams();

    const [loading, setLoading] = useState(true);
    const [has_content, seHasContent] = useState(false);
    const [posts, setPosts] = useState([]);
    const [posts_items, setPostsItems] = useState([]);
    const [other_posts, setOtherPosts] = useState([]);

    async function loadPage(){
         setLoading(true);
        try{
            const response = await api.post(`/news`);
            setPosts(response.data.data.posts);
            setPostsItems([]);
            setOtherPosts([]);
            setLoading(false);
        }catch(err){
            setLoading(false);
        }
    }

    async function loadPosts(){
         setLoading(true);
        try{
            const response = await api.post(`/news-site/${url}`);
            setPosts(response.data.data.posts);
            setPostsItems(response.data.data.posts.posts_items);
            setOtherPosts(response.data.data.other_posts);
            setLoading(false);
        }catch(err){
            setLoading(false);
        }
    }

    function TagList({ tags }) {
        const tagArray = tags ? tags.split(',') : [];
        return (<>
            {tagArray.map((tag, index) => (
                <a key={index} href={`#tag-${tag}`} className="btn btn-sm btn-primary-light me-2">
                    {tag}
                </a>
            ))}</>
        );
    }

    useEffect(() => {
        if(url){
            loadPosts();
            seHasContent(true);
        }else{
            loadPage();
            seHasContent(false)
        }
    }, [url]);

    function CardShimmer(){
        return (<div className="card custom-card">
            <div className="card-img-top">
                <Shimmer source={base} rounded={0} />
            </div>
            <div className="card-body pt-3">
                <div className="fw-semibold fs-17 text-black mb-2"><Shimmer width={200} /></div>
                <Shimmer height={16} />
                <Shimmer width={120} height={16} />
            </div>

            <div className="card-footer">
                <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                        <div className="avatar avatar-rounded me-2">
                            <Shimmer source={base} rounded={100} />
                        </div>
                        <div className="flex-fill">
                            <div className="fs-15 fw-semibold"><Shimmer width={150} height={16} /></div>
                            <div className="mb-0 text-muted fs-11"><Shimmer width={100} height={12} /></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }

    function numbers() {
        return Math.floor(Math.random() * (600 - 60 + 1)) + 60;
    }

    return (
        <>
        <div className="header-wrapper">
            <Header />
        </div>
        <div className="page-wrapper">
            <div className="content-wrapper">
                {!loading &&
                <>
                <Content>
                    
                    <div className="container-fluid">
                        {has_content ?
                        <div className="row">
                            <div className="col-xl-8 m-auto">
                                <div className="row">
                                    <div className="col-xl-12" data-aos="fade-up">
                                        <div className="card custom-card" data-aos="fade-up" data-aos-delay="50">
                                            <div className="card-body pb-0">
                                                <h4 className="fw-semibold">{posts.title}</h4>
                                                <div className="d-sm-flex align-items-cneter flex-column">
                                                    <div className="d-flex align-items-center flex-fill">
                                                        <p className="mb-0 fw-semibold">
                                                            <span className="fs-11 text-muted fw-normal">{posts.date}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <hr className="mb-1" />
                                            </div>
                                            {posts_items!='' &&
                                            <div className="card-body post-content">
                                                {posts_items.map((item, index) => (
                                                <div className="mb-3 fs-15" key={item.id} data-aos="fade-up" data-aos-delay={`${(index + 1) * 50}`}>
                                                    {item.type==="text" &&
                                                    <div dangerouslySetInnerHTML={{__html: item.text}} />}

                                                    {item.type==="podcast" &&
                                                    <Podcast type={item.podcast_type} id={item.podcast} />}
                                                
                                                    {item.type==="imagetext" &&
                                                    <ImageText type={item.image_type} image={item.image} text={item.text} />}

                                                    {item.type==="video" &&
                                                    <Videos videos={item.videos} />}
                                                    
                                                    {item.type==="image" &&
                                                    <Gallery images={item.gallery} />}

                                                    {item.type==="button" &&
                                                    <a href={item.link} target="_blank" className="btn btn-custom" style={{color: item.link_color, backgroundColor: item.link_bg}}>
                                                        {item.link_text}
                                                    </a>}

                                                </div>))}
                                            </div>}
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>:
                        <div className="content-news">
                            <h2 data-aos="fade-up" data-aos-delay="50">News</h2>
                            <div className="container-news" data-aos="fade-up" data-aos-delay="100">
                                <div className="container-fluid mt-5">
                                    <div className="row">
                                        {posts.map((item, index) => (
                                        <div class="col-md-4" key={index} data-aos="fade-up" data-aos-delay={`${(index + 1) * 50}`}>
                                            <Link to={`/novidades/${item.url}`} className="news-thumb">
                                                <img src={base} className="image-cover" style={{backgroundImage: `url(${item.image})`}} />
                                                <h4>{item.title}</h4>
                                                <p>{item.subtitle}</p>
                                            </Link>
                                        </div>))}
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </Content>
                <Footer />
                </>}
            </div>
        </div>
        </>
    );
}
