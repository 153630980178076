import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import { Carousel } from 'react-responsive-carousel';

import Base from '../assets/images/blank-image-vertical.png';

import image_2 from '../assets/images/test/image_2.jpg';
import image_3 from '../assets/images/test/image_3.jpg';
import image_4 from '../assets/images/test/image_4.jpg';
import image_5 from '../assets/images/test/image_5.jpg';
import image_7 from '../assets/images/test/image_7.jpg';
import image_10 from '../assets/images/test/image_10.jpg';

import LanguageContext from '../contexts/language';

export default function SessionTwo({ content, items }) {
    
    const { language } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');

    const groupedItems = chunkArray(items, 4);

    function chunkArray(array, chunkSize) {
        const chunks = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            chunks.push(array.slice(i, i + chunkSize));
        }
        return chunks;
    }

    return (
        <div className="session-two session-bg">
            <div className="container-xl">
                <div className="row">
                    <div className="col-md-4 d-flex align-items-center" data-aos="fade-up" data-aos-delay="100">
                        <div className="heading-block text-center">
                            <h2 data-aos="fade-down" data-aos-delay="100" dangerouslySetInnerHTML={{__html: lang=='pt' ? content.title : content.title_en }} />
                            <div data-aos="fade-down" data-aos-delay="150" dangerouslySetInnerHTML={{__html: lang=='pt' ? content.description : content.description_en }} />
                            <Link to="/" className="btn btn-primary btn-lg" data-aos="fade-down" data-aos-delay="200">{lang=='pt' ? content.link_text : content.link_text_en}</Link>
                        </div>
                    </div>
                    <div className="col-md-8" data-aos="fade-up" data-aos-delay="200">
                        <Carousel
                            className="carousel-left"
                            transitionTime={300}
                            infiniteLoop={true}
                            autoPlay={false}
                            showArrows={true}
                            showIndicators={false}
                            showStatus={false}
                            showThumbs={false}
                            stopOnHover={false}
                            renderArrowPrev={(onClickHandler, hasPrev, label) =>
                                hasPrev && (
                                    <a onClick={onClickHandler} title={label} className="btn-prev">
                                        <FiChevronLeft />
                                    </a>
                                )
                            }
                            renderArrowNext={(onClickHandler, hasNext, label) =>
                                hasNext && (
                                    <a onClick={onClickHandler} title={label} className="btn-next">
                                        <FiChevronRight />
                                    </a>
                                )
                            }
                        >   

                            {groupedItems.map((group, indexGroup) => (
                            <div className="row ms-2 me-2" key={indexGroup}>
                                {group.map((item, index) => (
                                    <div className="col-md-3 ps-2 pe-2" key={index}>
                                        <Link className="session-item">
                                            <img src={Base} className="image-rounded image-cover" style={{backgroundImage: `url(${item.image})`}} />
                                            <div className="session-title">{lang=='pt' ? item.text : item.text_en}</div>
                                        </Link>
                                    </div>
                                ))}
                            </div>))}
                        </Carousel>
                        <div className="session-text text-center">{lang=='pt' ? content.subtitle : content.subtitle_en }</div>
                    </div>
                    
                </div>
            </div>
        </div>
    );
}