import React, { useState, useContext, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';

import Header from '../../components/Header';
import HeaderMetodologia from '../../components/HeaderMetodologia';
import Footer from '../../components/Footer';
import Podcast from '../../components/Podcast';
import ImageText from '../../components/ImageText';
import Videos from '../../components/Videos';
import Gallery from '../../components/Gallery';

import api from '../../services/api';

import base from '../../assets/images/blank-square.png';

export default function Page() {

    const { url } = useParams();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState([]);
    const [page_items, setPageItems] = useState([]);

    async function loadPage(){
         setLoading(true);
        try{
            const response = await api.post(`/pages-site/${url}`);
            setPage(response.data.data.pages);
            setPageItems(response.data.data.pages.pages_items);
            setLoading(false);
        }catch(err){
            setLoading(false);
        }
    }

    useEffect(() => {
        if(url){
            loadPage();
        }else{
            navigate('/');
        }
    }, [url]);

    return (
        <div className="page-wrapper">
            <Header />
            {!loading &&
            <>
                <div className="content-wrapper page-content">
                    <h4 className="fw-semibold" data-aos="fade-up" data-aos-delay="50">{page.title}</h4>
                </div>
                <div className="content-wrapper page-content">
                    {page_items!='' &&
                    <div className="post-content">
                        {page_items.map((item, index) => (
                        <div className="content-container" key={item.id} data-aos="fade-up" data-aos-delay={`${(index + 1) * 50}`} style={{backgroundColor: item.page_bg, paddingTop: Number(item.page_top), paddingBottom: Number(item.page_bottom) }}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-10 m-auto">
                                        <div className="fs-15">
                                            {item.type==="text" &&
                                            <div dangerouslySetInnerHTML={{__html: item.text}} />}
                                            {item.type==="podcast" &&
                                            <Podcast type={item.podcast_type} id={item.podcast} />}
                                        
                                            {item.type==="imagetext" &&
                                            <ImageText type={item.image_type} image={item.image} text={item.text} />}
                                            {item.type==="video" &&
                                            <Videos videos={item.videos} />}
                                            
                                            {item.type==="image" &&
                                            <Gallery images={item.gallery} />}
                                            {item.type==="button" &&
                                            <a href={item.link} target="_blank" className="btn btn-custom" style={{color: item.link_color, backgroundColor: item.link_bg}}>
                                                {item.link_text}
                                            </a>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>))}
                    </div>}
                    <Footer />
                </div>
            </>}
        </div>
    );
}
