import React,  { useState, useEffect, useContext } from 'react';
import { FaInstagram } from "react-icons/fa";

import api from '../services/api';

import LanguageContext from '../contexts/language';

export default function SessionEight({ content }) {

    const { language } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');

    const [loading, setLoading] = useState(true);
    const [branch, setBranch] = useState([]);

    async function loadPage(){
        try{
            const response = await api.post('/branch');
            const groupedBranch = chunkArray(response.data.data.branch, 3);
            setBranch(groupedBranch);
            setLoading(false);
        }catch(err){
            setLoading(false);
        }
    }

    function chunkArray(array, chunkSize) {
        const chunks = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            chunks.push(array.slice(i, i + chunkSize));
        }
        return chunks;
    }

    useEffect(() => {
        loadPage();
    }, []);

    return (
        <div className="session-eight">
            <div className="container-xl">
                <div className="row">
                    <div className="col-md-12" data-aos="fade-up" data-aos-delay="200">
                        <div className="heading-block text-center mb-5">
                            <h2 data-aos="fade-down" data-aos-delay="100" dangerouslySetInnerHTML={{__html: lang=='pt' ? content.title : content.title_en }} />
                        </div>
                    </div>
                    {branch.map((group, indexGroup) => (
                        <div class="col-md-12" key={indexGroup}>
                            <div className="row">
                            {group.map((item, index) => (
                                <div class="col-md-4 m-auto" key={index} data-aos="fade-up" data-aos-delay={`${(index + 1) * 100}`}>
                                    <div className="branch-box">
                                        <h4>{item.name}</h4>
                                        <p>{item.address}, {item.number} {item.complement}<br />{item.neighborhood},<br />{item.city} - {item.state}</p>
                                        <div className="branch-link"><a href={item.instagram} target="_blank" className="branch-icon"><FaInstagram /></a></div>
                                    </div>
                                </div>
                            ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}