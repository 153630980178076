import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import ScrollToTop from './components/ScrollToTop';

import Soon from './pages/Soon';
import Home from './pages/Home';
import News from './pages/News';
import Page from './pages/Page';
import QuemSomos from './pages/QuemSomos';
import Franquia from './pages/Franquia';
import Contact from './pages/Contact';
import Xperience from './pages/Xperience';

import Page404 from './pages/Page404';

export default function Routing() {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <Routes>
                <Route path="/" exact element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/novidades/:url?" element={<News />} />
                <Route path="/pagina/:url?" element={<Page />} />
                <Route path="/quem-somos" element={<QuemSomos />} />
                <Route path="/franquia" element={<Franquia />} />
                <Route path="/contato" element={<Contact />} />
                <Route path="/xperience" element={<Xperience />} />
                
                <Route path="*" element={<Page404 />} />
            </Routes>
        </BrowserRouter>
    );
}