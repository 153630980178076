import React, { useEffect } from 'react';
import AOS from 'aos';
import './assets/css/bootstrap.min.css';
import './assets/css/fonts.css';
import './assets/css/styles.css';
import './assets/css/custom.css';
import 'aos/dist/aos.css';

import { LanguageProvider } from './contexts/language';
import { AuthProvider } from './contexts/auth';

import Routing from './routes';

function App() {
    useEffect(() => {
        AOS.init({
            once: true,
            duration: 1000
        });
    }, []);
    return (
        <LanguageProvider>
            <AuthProvider>
                <Routing />
            </AuthProvider>
        </LanguageProvider>
    );
}

export default App;