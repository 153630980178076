import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/logo_slogan.png';

import LanguageContext from '../contexts/language';

export default function HeaderVideo() {

    const { language } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');

    return (
        <div className="header-video" data-aos="fade">
            <div className="embed-container no-radius">
                <video autoPlay muted loop>
                    <source src="https://d309qpknnqn0vi.cloudfront.net/video_home_brukfit.mp4" type="video/mp4" />
                </video>
            </div>
            <div className="header-logo-btn">
                <img src={logo} />
                <Link to="#" className="btn btn-primary btn-lg">{language.schedule}</Link>
            </div>
        </div>
    );
}