import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaInstagram, FaFacebookF, FaLinkedinIn, FaYoutube, FaChevronDown } from "react-icons/fa";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import { Carousel } from 'react-responsive-carousel';

import banner from '../assets/images/bg-footer.png';
import logo from '../assets/images/logo.png';
import logo_nerdetcetera from '../assets/images/logo_nerdetcetera.png';

import Base from '../assets/images/blank-square.png';
import fokus from '../assets/images/fokus.png';
import powerade from '../assets/images/powerade.png';
import ink from '../assets/images/ink.png';

import LanguageContext from '../contexts/language';

export default function Footer() {

    const { language } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');

    return (
        <div className="footer-container">
            <div className="footer-all">
                <div className="footer-top">
                    <div className="footer-newsletter-text" data-aos="fade-up" data-aos-delay="50">
                        {language.partners}:
                    </div>
                    <Carousel
                        className="carousel-left"
                        transitionTime={300}
                        infiniteLoop={true}
                        autoPlay={false}
                        showArrows={true}
                        showIndicators={false}
                        showStatus={false}
                        showThumbs={false}
                        stopOnHover={false}
                        renderArrowPrev={(onClickHandler, hasPrev, label) =>
                            hasPrev && (
                                <a onClick={onClickHandler} title={label} className="btn-prev">
                                    <FiChevronLeft />
                                </a>
                            )
                        }
                        renderArrowNext={(onClickHandler, hasNext, label) =>
                            hasNext && (
                                <a onClick={onClickHandler} title={label} className="btn-next">
                                    <FiChevronRight />
                                </a>
                            )
                        }
                    >   
                        <div className="row ms-2 me-2">
                            <div className="col-md-4 ps-2 pe-2">
                                <img src={Base} className="image-rounded image-cover image-partner" style={{backgroundImage: `url(${fokus})`}} />
                            </div>
                            <div className="col-md-4 ps-2 pe-2">
                                <img src={Base} className="image-rounded image-cover image-partner" style={{backgroundImage: `url(${powerade})`}} />
                            </div>
                            <div className="col-md-4 ps-2 pe-2">
                                <img src={Base} className="image-rounded image-cover image-partner" style={{backgroundImage: `url(${ink})`}} />
                            </div>
                        </div>
                        <div className="row ms-2 me-2">
                            <div className="col-md-4 ps-2 pe-2">
                                <img src={Base} className="image-rounded image-cover image-partner" style={{backgroundImage: `url(${fokus})`}} />
                            </div>
                            <div className="col-md-4 ps-2 pe-2">
                                <img src={Base} className="image-rounded image-cover image-partner" style={{backgroundImage: `url(${powerade})`}} />
                            </div>
                            <div className="col-md-4 ps-2 pe-2">
                                <img src={Base} className="image-rounded image-cover image-partner" style={{backgroundImage: `url(${ink})`}} />
                            </div>
                        </div>
                    </Carousel>
                </div>
                <div className="footer-bottom">
                    <div className="footer-newsletter">
                        <div className="footer-newsletter-text" data-aos="fade-up" data-aos-delay="50">
                        {language.newsletter}:
                        </div>
                        <div className="session-form" data-aos="fade-up" data-aos-delay="100">
                            <input className="form-control session-input" placeHolder={language.input_email} type="email" />
                            <div className="session-button">
                                <a href="javascript:;" className="btn btn-primary btn-lg">{language.register}</a>
                            </div>
                        </div>
                    </div>
                    <div className="footer-logo-social" data-aos="fade-up" data-aos-delay="150">
                        <div className="footer-logo-container">
                            <Link to="/" className="menu-logo">
                                <img src={logo} />
                            </Link>
                            <div className="footer-logo">
                                <a href="https://www.instagram.com/brukfit/" target="_blank" className="menu-icon">
                                    <FaInstagram />
                                </a>
                                <a href="https://www.facebook.com/brukfit" target="_blank" className="menu-icon">
                                    <FaFacebookF />
                                </a>
                                <a href="https://www.linkedin.com/company/brukfit" target="_blank" className="menu-icon">
                                    <FaLinkedinIn />
                                </a>
                                <a href="https://www.youtube.com/@brukfit" target="_blank" className="menu-icon">
                                    <FaYoutube />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-menu">
                    <Link to="/quem-somos" className="menu-text">
                        <div className="menu-item-text">{language.menu_about}</div>
                    </Link>
                    <div className="menu-text">
                        <div className="menu-item-text">{language.menu_branch}</div>
                    </div>
                    <Link to="/franquia" className="menu-text">
                        <div className="menu-item-text">{language.menu_bruk}</div>
                    </Link>
                    <Link to="/xperience" className="menu-text">
                        <div className="menu-item-text">Xperience</div>
                    </Link>
                    <a href="https://reserva.ink/bruk.fit" target="_blank" className="menu-text">
                        <div className="menu-item-text">{language.menu_store}</div>
                    </a>
                </div>
                <div className="footer-copyright">
                    <div>©2024 BRUK FIT - {language.copyright}</div>
                    <div className="logo-nerdetcetera">
                        <div>{language.developed}</div>
                        <a href="https://nerdetcetera.com" target="_blank">
                            <img src={logo_nerdetcetera} />
                        </a>
                    </div>
                </div>
            </div>
            <img src={banner} />
        </div>
    );
}