import React from 'react';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Content from '../../components/Content';

export default function Page404() {

    return (
        <>
        <div className="header-wrapper">
            <Header />
        </div>
        <div className="page-wrapper">
            <div className="content-wrapper">
                <Content>
                    <div className="error-page">
                        <div className="container-fluid">
                            <div className="row text-center align-items-center justify-content-center h-100">
                                
                            </div>
                        </div>
                    </div>
                </Content>
            </div>
            <Footer />
        </div>
        </>
    );
}
