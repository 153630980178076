import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdAutoGraph, MdOutlineSmartToy, MdFitnessCenter, MdOutlineRealEstateAgent, MdOutlineHandshake, MdOutlineSpeakerNotes, MdShareLocation } from "react-icons/md";
import InputMask from "react-input-mask";

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Shimmer from '../../components/Shimmer';

import banner from '../../assets/images/banner.jpg';
import bruk_1 from '../../assets/images/bruk-1.png';
import bruk_2 from '../../assets/images/bruk-2.png';

import api from '../../services/api';

import LanguageContext from '../../contexts/language';

export default function Franquia() {
    
    const { language } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [city, setCity] = useState('');
    const [message, setMessage] = useState('');

    const [loading, setLoading] = useState(false);
    const [content, setContent] = useState([]);

    const [error, setError] = useState(false);
    const [error_message, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);

    async function submitContact(e){
        e.preventDefault();
        setLoading(true);
        try{
            if(name==="" || email==="" || phone==="" || city==="" || message===""){
                setLoading(false);
                setError(true);
                setErrorMessage('Preencha todos os campos!');
                return;
            }

            const response = await api.post('/contact-franquia', {
                name,
                email,
                phone,
                city,
                message,
            });

            setError(false);
            setLoading(false);
            setSuccess(true);
            setName('');
            setEmail('');
            setPhone('');
            setCity('');
            setMessage('');

        }catch(err){
            setLoading(false);
            setError(true);
            setErrorMessage('Erro ao enviar informações');
        }
    }

    async function loadPage(){
        try{
            const response = await api.post('/site-page/franquia');
            setContent(response.data.data.content);
            setLoading(false);
        }catch(err){
            setLoading(false);
        }
    }

    useEffect(() => {
        loadPage();
    }, []);

    return (
        <>
        <div className="header-wrapper">
            <Header />
        </div>
        <div className="page-wrapper">
            <div className="header-space"></div>
            <div className="content-wrapper page-content">
                <div className="row pt-5 pb-4">
                    <div className="content-section section-1">
                        <h2 data-aos="fade-up" data-aos-delay="100" dangerouslySetInnerHTML={{__html: lang=='pt' ? content.text_1 : content.text_en_1 }} />
                        <div className="col-md-8 m-auto">
                            <div className="row">
                                <div className="col-md-6 m-auto content-box-why" data-aos="fade-up" data-aos-delay="150">
                                    <div className="content-box-item">
                                        <MdAutoGraph />
                                        <div dangerouslySetInnerHTML={{__html: lang=='pt' ? content.text_2 : content.text_en_2 }} />
                                    </div>
                                </div>
                                <div className="col-md-6 m-auto content-box-why" data-aos="fade-up" data-aos-delay="200">
                                    <div className="content-box-item">
                                        <MdOutlineSmartToy />
                                        <div dangerouslySetInnerHTML={{__html: lang=='pt' ? content.text_3 : content.text_en_3 }} />
                                    </div>
                                </div>
                                <div className="col-md-6 m-auto content-box-why" data-aos="fade-up" data-aos-delay="250">
                                    <div className="content-box-item">
                                        <MdFitnessCenter />
                                        <div dangerouslySetInnerHTML={{__html: lang=='pt' ? content.text_4 : content.text_en_4 }} />
                                    </div>
                                </div>
                                <div className="col-md-6 m-auto content-box-why" data-aos="fade-up" data-aos-delay="300">
                                    <div className="content-box-item">
                                        <MdOutlineRealEstateAgent />
                                        <div dangerouslySetInnerHTML={{__html: lang=='pt' ? content.text_5 : content.text_en_5 }} />
                                    </div>
                                </div>
                                <div className="col-md-6 m-auto content-box-why" data-aos="fade-up" data-aos-delay="350">
                                    <div className="content-box-item">
                                        <MdOutlineSpeakerNotes />
                                        <div dangerouslySetInnerHTML={{__html: lang=='pt' ? content.text_6 : content.text_en_6 }} />
                                    </div>
                                </div>
                                <div className="col-md-6 m-auto content-box-why" data-aos="fade-up" data-aos-delay="400">
                                    <div className="content-box-item">
                                        <MdShareLocation />
                                        <div dangerouslySetInnerHTML={{__html: lang=='pt' ? content.text_7 : content.text_en_7 }} />
                                    </div>
                                </div>
                                <div className="col-md-8 m-auto content-box-why" data-aos="fade-up" data-aos-delay="450">
                                    <div className="content-box-item">
                                        <MdOutlineHandshake />
                                        <div dangerouslySetInnerHTML={{__html: lang=='pt' ? content.text_8 : content.text_en_8 }} />
                                    </div>
                                </div>
                                <div className="col-md-12 text-center mt-5" data-aos="fade-up" data-aos-delay="500">
                                    <a href="#franquia" className="btn-border-mini">{language.franchisee}</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="content-section section-2" data-aos="fade-up" data-aos-delay="50">
                        <div className="row">
                            <div className="col-md-6 m-auto pt-4 pb-4">
                                <h2 dangerouslySetInnerHTML={{__html: lang=='pt' ? content.text_9 : content.text_en_9 }} />
                                <div dangerouslySetInnerHTML={{__html: lang=='pt' ? content.text_10 : content.text_en_10 }} />
                            </div>
                        </div>

                    </div>

                    <div className="content-section section-3">
                        <div className="col-md-6 m-auto">
                            <h2 data-aos="fade-up" data-aos-delay="50" dangerouslySetInnerHTML={{__html: lang=='pt' ? content.text_11 : content.text_en_11 }} />
                        </div>
                        <div className="col-md-12 text-center mt-5" data-aos="fade-up" data-aos-delay="150">
                            <a href="#franquia" className="btn-border-mini">{language.franchisee}</a>
                        </div>
                    </div>

                    <div className="content-section section-4">
                        <div className="row">
                            <div className="col-md-6 m-auto" data-aos="fade-up" data-aos-delay="50">
                                <img src={bruk_1} />
                            </div>
                            <div className="col-md-6 m-auto" data-aos="fade-up" data-aos-delay="100">
                                <h2 dangerouslySetInnerHTML={{__html: lang=='pt' ? content.text_12 : content.text_en_12 }} />
                                <div dangerouslySetInnerHTML={{__html: lang=='pt' ? content.text_13 : content.text_en_13 }} />
                                <h2 className="pt-4" dangerouslySetInnerHTML={{__html: lang=='pt' ? content.text_14 : content.text_en_14 }} />
                                <div dangerouslySetInnerHTML={{__html: lang=='pt' ? content.text_15 : content.text_en_15 }} />
                            </div>
                        </div>
                    </div>

                    <div className="content-section section-5" data-aos="fade-up" data-aos-delay="50">
                        <div className="row row-first">
                            <div className="col-md-6 m-auto">
                                <h2 dangerouslySetInnerHTML={{__html: lang=='pt' ? content.text_16 : content.text_en_16 }} />
                                <div dangerouslySetInnerHTML={{__html: lang=='pt' ? content.text_17 : content.text_en_17 }} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="content-section section-6" id="franquia">
                    <h2 data-aos="fade-up" data-aos-delay="50" dangerouslySetInnerHTML={{__html: lang=='pt' ? content.text_18 : content.text_en_18 }} />
                    <div className="row pt-4 pb-4">
                        <div className="col-md-6 m-auto" data-aos="fade-up" data-aos-delay="150">
                            <div data-aos="fade-up" data-aos-delay="100" dangerouslySetInnerHTML={{__html: lang=='pt' ? content.text_19 : content.text_en_19 }} />
                            <div className="row gy-3">
                                <div className="col-xl-10 m-auto mt-4">
                                    <label className="form-label">{language.form_name}:</label>
                                    <input type="text" className="form-control session-input" value={name} onChange={e => setName(e.target.value)} />
                                </div>
                                <div className="col-xl-10 m-auto">
                                    <label className="form-label">{language.form_email}:</label>
                                    <input type="email" className="form-control session-input" value={email} onChange={e => setEmail(e.target.value)} />
                                </div>
                                <div className="col-xl-10 m-auto">
                                    <label className="form-label">{language.form_phone}:</label>
                                    <InputMask type="tel" className="form-control session-input" mask="(99) 99999-9999" value={phone} onChange={e => setPhone(e.target.value)} />
                                </div>
                                <div className="col-xl-10 m-auto">
                                    <label className="form-label">{language.form_city}:</label>
                                    <input type="text" className="form-control session-input" value={city} onChange={e => setCity(e.target.value)} />
                                </div>
                                <div className="col-xl-10 m-auto">
                                    <label className="form-label">{language.form_message}:</label>
                                    <textarea className="form-control session-input" rows="3" value={message} onChange={e => setMessage(e.target.value)}></textarea>
                                </div>
                                <div className="col-xl-10 m-auto">
                                    <div className="text-end">
                                        <button className="btn btn-primary btn-lg" onClick={submitContact}>{language.send}</button>
                                    </div>
                                </div>
                                {error &&
                                <div className="col-xl-10 m-auto mt-4">
                                    <div className="alert alert-danger">
                                        {error_message}
                                    </div>
                                </div>}
                                {success &&
                                <div className="col-xl-10 m-auto mt-4">
                                    <div className="alert alert-success">
                                       {language.send_success}
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <Footer />
        </div>
        </>
    );
}
