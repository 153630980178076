import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Header from '../../components/Header';
import HeaderVideo from '../../components/HeaderVideo';
import SessionOne from '../../components/SessionOne';
import SessionTwo from '../../components/SessionTwo';
import SessionThree from '../../components/SessionThree';
import SessionFour from '../../components/SessionFour';
import SessionFive from '../../components/SessionFive';
import SessionSix from '../../components/SessionSix';
import SessionSeven from '../../components/SessionSeven';
import SessionEight from '../../components/SessionEight';
import SessionTen from '../../components/SessionTen';
import Banner from '../../components/Banner';

import Footer from '../../components/Footer';

import Shimmer from '../../components/Shimmer';

import api from '../../services/api';

export default function Home() {
    
    const [loading, setLoading] = useState(true);
    const [content, setContent] = useState([]);

    async function loadPage(){
        try{
            const response = await api.post('/home');
            setContent(response.data.data.content);
            setLoading(false);
        }catch(err){
            setLoading(false);
        }
    }

    useEffect(() => {
        loadPage();
    }, []);

    return (
        <>
            <div className="header-wrapper">
                <Header />
            </div>
            <div className="page-wrapper">
                <div className="header-space"></div>
                <HeaderVideo />
                {content.map(item => (<>
                    {item.session==="session_1" &&<SessionOne content={item} items={item.items} items_2={item.items_2} />}
                    {item.session==="session_2" &&<SessionTwo content={item} items={item.items_3} />}
                    {item.session==="session_3" &&<SessionThree content={item} items={item.items_4} />}
                    {item.session==="session_4" &&<SessionFour content={item} items={item.items_5} />}
                    {item.session==="session_5" &&<SessionFive content={item} items={item.items_6} />}
                    {item.session==="session_6" &&<SessionSix content={item} />}
                    {item.session==="session_7" &&<SessionSeven content={item} />}
                    {item.session==="session_8" &&<SessionEight content={item} />}
                    {item.session==="session_10" &&<SessionTen  content={item}/> }
                    {item.session==="banner" &&<Banner items={item.items_7} />}
                </>))}
                <Footer />
            </div>
        </>
    );
}
